<template>
  <div class="page-wrapper">
    
    <section class="hero">
      <h1 class="title">Propulsez votre<br>entreprise</h1>
      
      <div class="hero_carousel main-carousel">
        <div class="carousel-cell">
          <h2 class="title small"><span class="color2">Astuce #1</span></h2>
          <p class="text">Ayez un site abordable aux dernières tendances du web</p>
          <img src="@/assets/img/hero-2.jpg" alt="Google ads">
        </div>
        <div class="carousel-cell">
          <h2 class="title small"><span class="color3">Astuce #2</span></h2>
          <p class="text">Apparaissez dans les 1er résultats Google</p>
          <img src="@/assets/img/hero-1.jpg" alt="Google ads">
        </div>
        <div class="carousel-cell">
          <h2 class="title small"><span class="color2">Astuce #3</span></h2>
          <p class="text">Répondez rapidement à tous vos nouveaux clients potentiels</p>
          <img src="@/assets/img/hero-3.jpg" alt="Google ads">
        </div>
      </div>
    </section>

    <section class="projets">
      <h2 class="title">Générez des<br>résultats comme<br>nos clients</h2>
      <div class="projet_carousel main-carousel-2">
        <div class="carousel-cell">
          <img src="@/assets/img/projet-1.jpg" alt="Google ads">
          <p class="text">Recevez des demandes<br> de <span>soumission</span></p>
        </div>
        <div class="carousel-cell">
          <img src="@/assets/img/projet-2.jpg" alt="Google ads">
          <p class="text">Recevez des demandes<br> de <span>rendez-vous</span></p>
        </div>
        <div class="carousel-cell">
          <img src="@/assets/img/projet-3.jpg" alt="Google ads">
          <p class="text">Générez des <span>ventes en ligne</span></p>
        </div>
        <div class="carousel-cell">
          <img src="@/assets/img/projet-4.jpg" alt="Google ads">
          <p class="text">Recevez des <span>candidatures</span> d’emploi</p>
        </div>
      </div>
    </section>

    <section id="contact" class="contact">
      <h2 class="title">Curieux ?<br> On peut vous aider.</h2>
      <a href="" class="cta" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/julienaubrey/discutons-de-vos-objectifs-d-affaires'});return false;"><p class="text">Planifiez un appel</p></a>
      <img src="@/assets/img/jnv.jpg" alt="J&V">
      <div class="infos">
        <div class="row">
          <img src="@/assets/img/julien-aubrey.png" alt="Julien Aubrey">
          <h3 class="title small">Julien Aubrey</h3>
        </div>
        <div class="row">
          <a href="tel:4506756848" class="color">450 675-6848</a>
          <a href="mailto:julien@jnv.dev">julien@jnv.dev</a>
        </div>
      </div>
    </section>

    <footer class="footer">
      <p class="text">Faites équipe avec</p>
      <svg class="logo" viewBox="0 0 60 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M47.6044 28.6784H47.4523L35.3611 0H39.2394L47.4523 20.7976H47.6044L56.1215 0H59.9999L47.6044 28.6784Z" fill="black"/>
        <path d="M37.2625 23.258L40.6846 28.7553H36.8823L35.111 26.2565C33.2605 27.9224 31.1566 28.7553 28.7992 28.7553C26.0362 28.7553 23.6915 27.8199 21.765 25.949C19.8385 24.0781 18.8752 21.7715 18.8752 19.0293C18.8752 17.4147 19.2935 15.8897 20.13 14.4545C20.9918 12.9937 22.1452 11.8148 23.5901 10.9178C22.2719 9.20068 21.6129 7.67577 21.6129 6.34309C21.6129 4.60034 22.2593 3.11388 23.552 1.8837C24.8448 0.627901 26.3911 0 28.1908 0C29.6357 0 30.8904 0.422872 31.9551 1.26862C33.08 2.11436 34.357 4.42094 34.4108 6.2662H31.2707C30.6085 4.03651 29.5343 3.38298 28.1908 3.38298C27.3036 3.38298 26.5685 3.65208 25.9855 4.19028C25.4278 4.72848 25.149 5.44608 25.149 6.34309C25.149 7.21446 25.4405 8.03457 26.0235 8.80343L35.0349 20.7207L36.8823 16.6074H40.206L38.7834 19.9903L37.2625 23.258ZM22.0692 19.0293C22.0692 20.8745 22.7155 22.4251 24.0083 23.6809C25.3011 24.911 26.8981 25.5261 28.7992 25.5261C30.5989 25.5261 32.0565 24.911 33.1718 23.6809L25.5292 13.4935C24.4646 14.0829 23.6154 14.8774 22.9817 15.8769C22.3733 16.8765 22.0692 17.9272 22.0692 19.0293Z" fill="black"/>
        <path d="M8.47908 28.9091C6.42585 28.9091 4.65146 28.3453 3.15589 27.2176C0.91255 25.5261 0 23.258 0 21.1436H3.42205C3.99239 24.0268 5.89353 25.5261 8.47908 25.5261C9.8986 25.5261 11.0393 25.0264 11.9011 24.0268C12.7883 23.0017 13.2319 21.6818 13.2319 20.0672V0H16.73V19.7597C16.73 22.6044 15.9822 24.847 14.4867 26.4872C13.0165 28.1018 11.0139 28.9091 8.47908 28.9091Z" fill="black"/>
      </svg>
    </footer>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import Flickity from 'flickity'

export default {
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  },
  data() {
      return {
          teamCarousel: null,
      }
  },
  mounted() {
    setTimeout(() => {
        this.teamCarousel = new Flickity(document.querySelector('.main-carousel'), {
            wrapAround: true,
            imagesLoaded: true,
            prevNextButtons: false,
            pageDots: true,
            autoPlay: false,
        })
        this.teamCarousel = new Flickity(document.querySelector('.main-carousel-2'), {
            wrapAround: true,
            imagesLoaded: true,
            prevNextButtons: false,
            pageDots: true,
            autoPlay: false,
        })

    }, 500)
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';
</style>
