<template>
  <div id="app">
    <nav-bar></nav-bar>
    <div class="main-wrapper">
      <router-view />
    </div>
  </div>
</template>
<script>
import NavBar from '@/components/NavBar'

export default {
  components: { NavBar },
  methods: {
    toogleNavBarStatus() {
      const st = document.documentElement.scrollTop

      if (!document.querySelector('.projets')) {
        return
      }

      if (st >= 200 && st <= 800) {
        document.querySelector('body').classList.add('background')
      } else {
        document.querySelector('body').classList.remove('background')
      }
    },
  },
  created() {
    // NavBar Follow
    this.toogleNavBarStatus()
    window.addEventListener('scroll', () => {
      this.toogleNavBarStatus()
    })
  },
}
</script>

<style lang="scss">

  @import '@/theme/variables.scss';
  @import '@/theme/general.scss';
  @import '@/theme/flickity.scss';

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-family: $font-regular;
    transition: all 0.5s ease;
    color: $color1;

    &.background {
      background-color: $color1;
      color: #fff;

      .flickity-page-dots .dot {
        background: #fff;
      }

      .flickity-page-dots .dot.is-selected {
        background: $color2;
      }

      .projets {
        .title {
          color: #fff;
        }
        .projet_carousel {
          .carousel-cell {
            .text {
              color: #fff;
            }
          }
        }
      }
    }
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }

  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
    padding: 0;
  }

</style>
